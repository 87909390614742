<template>
  <div class="container h-100 align-items-center">
    <div id="load" class="sk-cube-grid" v-bind:class="{ 'hide': loading }">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
        </div>
    <div class="row justify-content-center mt-5" style="display:none">
      <div class="col-5 login">
        <form @submit.prevent="onSubmit" novalidate>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="email"
              name="email"
              v-validate="'required|email'"
              placeholder="E-mail"
            />
            <b-form-invalid-feedback :state="!errors.has('email')">
              Por favor, insira seu e-mail.
            </b-form-invalid-feedback>
          </div>

          <div class="form-group">
            <input
              type="password"
              class="form-control"
              v-model="password"
              name="password"
              v-validate="'required'"
              placeholder="Senha"
            />
            <b-form-invalid-feedback :state="!errors.has('password')">
              Por favor, insira sua senha.
            </b-form-invalid-feedback>
          </div>

          <b-form-invalid-feedback :state="!error">
            Seu e-mail ou senha está incorreto!
          </b-form-invalid-feedback>

          <button type="submit" class="btn btn-primary">Login</button>
        </form>
      </div>
    </div>

    <!-- modals -->
    <BaseModalSelectBlog :blogs="blogs" @selected="selectBlog" />
  </div>
</template>

<script>
import AuthService from "@/services/resources/AuthService";
const service = AuthService.build();
import Cookies from "js-cookie";
import RedirectService from "@/services/resources/RedirectService";
const serviceRedirect = RedirectService.build();
const axios = require("axios");
import store from '@/store/index'



export default {
  data() {
    return {
      submit: false,
      error: false,
      email: null,
      password: null,
      blogs: [],
      loading:true,
    };
  },

  methods: {
    onSubmit() {
      this.submit = true;

      this.$validator.validateAll().then((result) => {
        if (result) {
          var that = this;

          let data = {
            username: this.email,
            password: this.password,
          };

          this.$store
            .dispatch("loginRequest", data)
            .then((resp) => {
              let blogs = resp.site;

              if (blogs.length == 0) {
                // User dosen't hava a blog, so redirect him to the welcome page
                this.$router.push("/welcome");
              } else if (blogs.length == 1) {
                // User has just one blog, so go ahead and login him
                this.$store.dispatch("blogSelected", blogs[0]);
              } else {
                // User has more than one blog, so show a dialog to choose one
                this.blogs = blogs;
                this.$bvModal.show("modalSelectBlog");
              }
            })
            .catch(() => {
              that.error = true;
            });
        }
      });
    },
    selectBlog(blog) {
      this.$store.dispatch("blogSelected", blog);
    },
    userRdrk() {
      this.$store
      .dispatch("logoutToRdrkRequest")
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const params = urlParams.get("rdrk");
      console.log(params)
      serviceRedirect
        .read(params)
        .then((resp) => {
          console.log(resp);
          this.$store.dispatch("blogSelected", resp)
          Cookies.set("access_token", resp.auth, { expires: 365 });
          // var config = {
          //   headers: { Authorization: resp.auth },
          // };
          // if (window.location.host === "localhost") {
          //   var apiKey = "https://api.innovaweb.com.br";
          // } else {
          //   var apiKey = "https://api.gdigital.com.br";
          // }
          // axios
          //   .get(apiKey + "/me", config)
          //   .then((resp) => {
          //     console.log(resp);
          //     let blogs = resp.data.availableBlogs;
          //     Cookies.set("blogs", JSON.stringify(blogs), { expires: 365 });
          //     if (blogs.length == 0) {
          //       this.$router.push("/broadcast");
          //     } else if (blogs.length == 1) {
          //       this.$store.dispatch("blogSelected", blogs[0]);
          //     } else {
          //       this.blogs = blogs;
          //       console.log(this.blogs);
          //       this.$bvModal.show("modalSelectBlog");
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
          //
        })
        .catch((err) => {
          console.log(err);
          // window.history.back()
        });
    },
  },
  mounted() {
    this.userRdrk();
  },
};
</script>
<style lang="scss" scoped>
.login {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
}

.h-100 {
  height: 100vh;
}

.sk-cube-grid {
  width: 80px;
  height: 80px;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  z-index: 999999999999999999999999999999999999999;  
  display: block
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}

</style>
